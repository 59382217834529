import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {checkUser} from "../../funcs/checkUser";

import { generateClient } from "aws-amplify/api";
import { createUser } from "../../graphql/mutations";

import Header from "../Header";





const InAppUserCreate = (props) => {

  const client = generateClient();

  const [userDets, setUserDets] = useState();
  const [selectedInterests, setSelectedInterests] = useState([]);
  const naviagte = useNavigate();

  const intrestCats = [
    'GAMING',
    'SPORTS',
    'MUSIC',
    'MOVIES',
    'BOOKS',
    'TECHNOLOGY',
  ]
  
  useEffect(() => {
    fetchUser();
  },[])

  useEffect(() => {
    setUserDets(prevState => ({
      ...prevState,
      interests: selectedInterests
    }));
  }, [selectedInterests]);

  useEffect(() => {
    setUserDets({
      email: props.user.signInDetails.loginId
    })
  }, [])

  async function createInAppUser(userDets) {
    try{
    await client.graphql({
      query: createUser,
      variables: {
        input: userDets,
      }
    })} catch (err) {
      console.log(err);
    }
  }

  const fetchUser = async() => {
    try{
      console.log('checking user...');
      const userEmail = props.user.signInDetails.loginId;
      const data = await checkUser(userEmail);
      if(data){
        naviagte('/home');
      }
    }
    catch (err) {
      console.log(err);
   }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'interests' && value && !selectedInterests.includes(value)) {
      setSelectedInterests([...selectedInterests, value]);

    }
    else {
      setUserDets(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };


  const removeInterests = (interestToRemove) => {
    setSelectedInterests(selectedInterests.filter(interests => interests !== interestToRemove));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      await createInAppUser(userDets);
      onSuccess();
    } catch (err) {
      console.log(err);
    }
    console.log(userDets);
    console.log(props.user)
    console.log(selectedInterests);
  }

  const onSuccess = () =>{
    naviagte('/home');
  }

  return (
    <>
      <Header />
      <div className="container pt-5" >
        <div className="row">
          <div className="col-md-6 p-4 bg-dark text-white border-right">
            <h2 className="mb-4">Let's get to know you</h2>
            <p>Please fill out the form to help us understand you better.</p>
          </div>
          <div className="col-md-6 p-4">
            <div className="mb-3">
              <label htmlFor="username" className="form-label">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                onChange={handleInputChange}
                className="form-control bg-dark text-white"
              />
            </div>

            {/* <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">Phone Number</label>
              <input
                type="tel"
                id="phone_number"
                name="phone_number"
                onChange={handleInputChange}
                className="form-control bg-dark text-white"
              />
            </div> */}

            <div className="mb-3">
              <label htmlFor="interests" className="form-label">Interest</label>
              <select
                name="interests"
                onChange={handleInputChange}
                className="form-select bg-dark text-white"
              >
                <option value="">Select an interest</option>
                {intrestCats.map(interests => (
                  <option key={interests} value={interests}>{interests}</option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <h5>Selected Interests:</h5>
              <ul className="list-group">
                {selectedInterests.map(interests => (
                  <li key={interests} className="list-group-item d-flex justify-content-between align-items-center bg-dark text-white">
                    {interests}
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => removeInterests(interests)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <button
              onClick={handleSubmit}
              className="btn btn-primary w-100"
              
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InAppUserCreate;