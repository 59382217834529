import {useEffect, useState, useContext} from 'react';
import { listCommunities, listCommunityMembers } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalStateContext } from '../GlobalStateContext';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import { data } from 'autoprefixer';
import { checkUser } from '../funcs/checkUser';

export const CommunityList = () => {
    
    const location = useLocation();

    const [communities, setCommunities] = useState();
    const { user } = useAuthenticator();
    const client = generateClient({authMode: 'userPool'});
    const { inAppUser, setInAppUser } = useContext(GlobalStateContext || location.state.inAppUser);


    useEffect(() => {
        async function fetchComunites(){
            setCommunities(null);
            if (!inAppUser) {
                setInAppUser(await checkUser(user.signInDetails.loginId));
                return;
            }
            const  query = await {filter: {userCommunityMembershipsId: {eq: inAppUser.id}}}
            let communityIds = []
            try {
                const userCommunities = await client.graphql({query: listCommunityMembers, variables: query})
                    .then(async (data) => {
                        communityIds = await data.data.listCommunityMembers.items.map(item => item.communityMembersId);
                        console.log(data)
                    })
                    .then(async() => {
                        return await client.graphql({query: listCommunities, variables: {filter: {id: {in: communityIds}}}});
            });

                if (userCommunities && !ignore) {
                    setCommunities(userCommunities.data.listCommunities.items);
                }
            } catch (err) {
                console.log(err);
            }
        }

        let ignore = false;
        fetchComunites();
        return () => { ignore = true; }
    }, [inAppUser]);

    return (
        <div>
            <Header />
            <div className="container mt-4">
                <div className="row">
                    {communities && communities.map(community => (
                        <div key={community.id} className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h5 className="card-title">{community.name}</h5>
                                    <p className="card-text">{community.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}