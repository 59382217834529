/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      interests
      profilePicture
      communitiesOwned {
        nextToken
        __typename
      }
      communityMemberships {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        username
        email
        interests
        profilePicture
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommunity = /* GraphQL */ `
  query GetCommunity($id: ID!) {
    getCommunity(id: $id) {
      id
      name
      description
      type
      tags
      canvas
      members {
        nextToken
        __typename
      }
      basePixelsAllowed
      createdAt
      updatedAt
      userCommunitiesOwnedId
      owner
      __typename
    }
  }
`;
export const listCommunities = /* GraphQL */ `
  query ListCommunities(
    $id: ID
    $filter: ModelCommunityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCommunities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        type
        tags
        canvas
        basePixelsAllowed
        createdAt
        updatedAt
        userCommunitiesOwnedId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommunityMember = /* GraphQL */ `
  query GetCommunityMember($id: ID!) {
    getCommunityMember(id: $id) {
      id
      user {
        id
        username
        email
        interests
        profilePicture
        createdAt
        updatedAt
        owner
        __typename
      }
      community {
        id
        name
        description
        type
        tags
        canvas
        basePixelsAllowed
        createdAt
        updatedAt
        userCommunitiesOwnedId
        owner
        __typename
      }
      role
      pixelsToday
      createdAt
      updatedAt
      userCommunityMembershipsId
      communityMembersId
      owner
      __typename
    }
  }
`;
export const listCommunityMembers = /* GraphQL */ `
  query ListCommunityMembers(
    $id: ID
    $filter: ModelCommunityMemberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCommunityMembers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        role
        pixelsToday
        createdAt
        updatedAt
        userCommunityMembershipsId
        communityMembersId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
