import Header from "../../components/Header";
import CarouselComponent from "../../components/CarouselComponent";
import DrawingCanvas from "../../components/Canvas";

import { listCommunities } from "../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { list } from "aws-amplify/storage";



import { isMobile } from 'react-device-detect';
import { Button } from "@blueprintjs/core";
import { useContext, useEffect, useState } from "react";
import { GlobalStateContext } from "../../GlobalStateContext";
import { fetchInAppUser } from "../../funcs/fetchUser";

// import { signOut } from "aws-amplify/auth";

function Home(props) {

    const deviceType = isMobile ? "mobile" : "desktop";
    const client = generateClient({authMode: 'userPool'});
    const { inAppUser, setInAppUser } = useContext(GlobalStateContext);

    const [communities, setCommunities] = useState({
        GAMING: [],
        SPORTS: [],
        MUSIC: [],
        MOVIES: [],
        BOOKS: [],
    });



    useEffect(() => {
        const fetchCommunities = async () => {
            try {
                const response = await client.graphql({ query: listCommunities });
                console.log(response);
                if (response.data.listCommunities.items.length > 0) {

                    response.data.listCommunities.items.forEach(community => {
                        if (!communities[community.type]) return;
                        console.log(community.type)
                        setCommunities(prevState => {
                            if (prevState[community.type].some(item => item.id === community.id)) {
                                return prevState;
                            }
                            return {
                                ...prevState,
                                [community.type]: [...prevState[community.type], community]
                            }
                        });
                    });
                    console.log(communities);
                }
            } catch (err) {
                console.log('Error fetching communities:', err);
            }
        }
        fetchCommunities();
        if (!inAppUser) {
            try {
                fetchInAppUser().then(user => {
                    setInAppUser(user);
                });
            } catch (err) {
                console.error('Error fetching user:', err);
            }
        }
    }, [])



    function saveCanvasLocally() {
        const canvas = document.getElementById('testCanvas'); // Ensure you have a canvas element with this ID
        const image = canvas.toDataURL('image/jpg').replace("image/jpg", "image/octet-stream"); // Convert canvas to PNG

        // Create a temporary link to trigger the download
        let downloadLink = document.createElement('a');
        downloadLink.setAttribute('download', 'CanvasImage.jpg'); // Name the file
        downloadLink.setAttribute('href', image);
        document.body.appendChild(downloadLink); // Append to document to make it work on Firefox
        downloadLink.click(); // Trigger the download
        document.body.removeChild(downloadLink); // Clean up
    }

    return (
        <div >

            <Header clearUser={props.clearUser} />
            <div className="container mx-auto p-3">
                <div>
                    {/* <UserCreateForm /> */}
                </div>
                <CarouselComponent title="Gaming" deviceType={deviceType} communities={communities['GAMING']} className='mr-3' />
                <CarouselComponent title="New" deviceType={deviceType} />
                <CarouselComponent title="Top" deviceType={deviceType} />
            </div>

        </div>
    )
}

export default Home;