import React
from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
// import { Authenticator } from '@aws-amplify/ui-react';
import { signOut } from 'aws-amplify/auth'; // Adjust the import paths as needed

import { Link } from 'react-router-dom';

const Header = (props) => {
    // // const user = props.user;
    // const [isUser, setIsUser] = useState(false);
    // const { user, signOut } = useAuthenticator((context) => [context.user]);
    const toSignIn = () => {console.log('signing out')}

    
    const authStatus= 'authenticated';

    // useEffect(() => {
    //     const getUser = async () => {
    //         try {
    //             const userData = await getCurrentUser();
    //             if (userData) {
    //                 setIsUser(true);
    //             }
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     };

    //     getUser();
    // }, []);
    // const {toSignIn, authStatus} = useAuthenticator(); 
    
    return (
        <Navbar bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="#home">Our Place</Navbar.Brand>
                <Nav className="justify-end">
                    <Nav.Link as={Link} to={"/"}>Home</Nav.Link>
                    <Nav.Link as={Link} to="/create-community">Create Community</Nav.Link>
                    <Nav.Link as ={Link} to="/my-communities">My Communities</Nav.Link>
                    {authStatus === 'authenticated' ? (
                        <NavDropdown title="Profile" id="basic-nav-dropdown" alignRight>
                            <NavDropdown.Item href="#settings">Settings</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={signOut}>Sign Out</NavDropdown.Item>
                        </NavDropdown>
                    ) : (
                        <Nav.Link  onClick={toSignIn }>Log in</Nav.Link>
                    )}
                </Nav>
            </Container>
        </Navbar>
    );
};

export default Header;