import './App.css';
import { Amplify } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'

import { GlobalStateContext, GlobalStateProvider } from './GlobalStateContext.js';
import InAppUserCreate from './components/forms/InAppUserCreate';
import CommunityCreateForm from './components/forms/CreateCommunityForm.jsx';
import Home from './app/routes/home.jsx';
import CommunityView from './components/CommunityView.jsx';
import { CommunityList } from './components/CommunityList.jsx';

import {
  Authenticator,
} from '@aws-amplify/ui-react';

// import { theme } from './components/themes/darkTheme';

import '@aws-amplify/ui-react/styles.css';


// import '@aws-amplify/ui-react/styles.css';

import awsExports from "./aws-exports";
import { getCurrentUser, signOut } from 'aws-amplify/auth';


import { listUsers } from './graphql/queries';

import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { generateClient } from 'aws-amplify/api';

import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';
import context from 'react-bootstrap/esm/AccordionContext.js';



Amplify.configure(awsExports);

function App() {
  // const [user] = useAuthenticator((context) => [context.user]);
  const { inAppUser, setInAppUser } = useContext(GlobalStateContext);


  const [colorMode, setColorMode] = useState('light');


  // async function checkUser() {
  //   try {
  //     const userData = await getCurrentUser();
  //     setUser(userData);
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }
  // }



  const client = generateClient({authMode: 'userPool'});

  const getAppUserByCognitoEmail = async (userEmail) => {
    console.log(userEmail, '  in getAppUserByCognitoEmail');
    const query = { filter: { email: { eq: userEmail } } };
    const response = await client.graphql({ query: listUsers, variables: query });
    return response.data;
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();
        const data = await getAppUserByCognitoEmail(user.signInDetails.loginId);
        console.log(data);
        if (data && data.listUsers && data.listUsers.items) {
          if (data.listUsers.items.length > 0) {
            setInAppUser(data.listUsers.items[0]);
          } else {
            setInAppUser(false);
          }
        } else {
          setInAppUser(false);
        }
      } catch (err) {
        console.log(err);
        setInAppUser(false);
      }
    };

    fetchUser();
  }, [setInAppUser]);

  return (
    <GlobalStateProvider>
      <Authenticator  >
        {({ signOut, user }) => (
          <BrowserRouter>

            <Routes>
              <Route path='/home' element={<Home user={user} />} />
              <Route path='/' element={(user ? (inAppUser ? <Navigate to={'/home'} /> : <Navigate to={'/create-user'} />) : <Home />)} />
              <Route path='/home' element={<Home />} />
              <Route path='/create-user' element={<InAppUserCreate user={user} />} />
              <Route path='/create-community' element={<CommunityCreateForm inAppUser={inAppUser || false} />} />
              <Route path='/community/:id' element={<CommunityView inAppUser={inAppUser || false} />} />
              <Route path='/my-communities' element={<CommunityList />}/>
            </Routes>
          </BrowserRouter>
        )}
      </Authenticator>
    </GlobalStateProvider>
  );
}

export default App;
