import { Auth } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { listUsers } from '../graphql/queries';

export async function checkUser(userEmail) {
        const client = generateClient({authMode: 'userPool'});
    try {
        const query = { filter: {email: {eq: userEmail}} };
        const response = await client.graphql({ query: listUsers, variables: query });
        const user =  response.data;

        if(user){
            try{
                if(user.listUsers.items.length > 0){
                    return user.listUsers.items[0];
                }
            }catch(err){ 
              return false;
            }
        }

        
    } catch (err) {
        console.log(err);
    }
}
