import React, { useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { uploadData, list } from 'aws-amplify/storage';
import { createCommunity, createCommunityMember } from '../../graphql/mutations';

import { useAuthenticator } from '@aws-amplify/ui-react';

import Header from '../Header';

const CommunityCreateForm = (props) => {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [tags, setTags] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [basePixels, setBasePixels] = useState(1);
    const { user } = useAuthenticator();

    const client = generateClient();

    useEffect(() => {
        console.log(props.inAppUser);
    }, []);

    const communityCats = [
        'GAMING',
        'SPORTS',
        'MUSIC',
        'MOVIES',
        'BOOKS',
        'TECHNOLOGY',
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name || !description || !type) {
            setError('All fields are required');
            return;
        }


        const canvasKey = `canvassitea568a606/canvases/${name}-${Date.now()}.json`;





        const communityInput = {
            name: name,
            description: description,
            type: type,
            basePixelsAllowed: basePixels,
            tags: tags.split(',').map(tag => tag.trim()),
            canvas: canvasKey,
            owner: user.userId
        };

        try {
            const response = await client.graphql({
                query: createCommunity,
                variables: { input: communityInput }
            });

            const communityId = response.data.createCommunity.id;
            const userId = props.inAppUser.id;
            const blankCanvas = JSON.stringify({ "communityId": communityId, "pixels": [] });
            try {
                // const result = await list({ path: 'canvases/' });
                const canvasUrl = await uploadData({ path: canvasKey, data: blankCanvas });
                await canvasUrl.result
                console.log(canvasUrl);
            } catch (err) {
                console.log(err);
            }
            console.log(response.data.createCommunity);

            const communityMemberInput = {
                role: "OWNER",
                pixelsToday: 0,
                userCommunityMembershipsId: userId,
                communityMembersId: communityId,
            };
            console.log(communityMemberInput);

            await client.graphql({
                query: createCommunityMember,
                variables: { input: communityMemberInput }
            });

            console.log(communityInput);
            setSuccess('Community created successfully!');
            setError('');
            setName('');
            setDescription('');
            setType('');
            setTags('');
            setBasePixels(1);
        } catch (err) {
            setError('Error creating community');
            setSuccess('');
            console.error('Error creating community:', err);
        }
    };

    return (
        <>
            <Header />
            <div className="container mt-5 pb-5">
                <h2>Create a New Community</h2>
                {error && <div className="alert alert-danger">{error}</div>}
                {success && <div className="alert alert-success">{success}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label ">Community Name</label>
                        <input
                            type="text"
                            className="form-control bg-dark text-white"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                            className="form-control bg-dark text-white"
                            id="description"
                            rows="3"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div className="mb-3">
                        <label htmlFor='basePixels' className='form-label'>User Pixel Limit Per Day</label>
                        <input
                            type="number"
                            className="form-control bg-dark text-white"
                            id="basePixels"
                            value={basePixels}
                            onChange={(e) => setBasePixels(parseInt(e.target.value))}
                            min="1"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="category" className="form-label ">Category</label>
                        <select
                            name="type"
                            className="form-select bg-dark text-white"
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="">Select a category</option>
                            {communityCats.map(cat => (
                                <option key={cat} value={cat}>{cat}</option>
                            ))}

                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="tags" className="form-label">Tags (comma separated)</label>
                        <input
                            type="text"
                            className="form-control bg-dark text-white"
                            id="tags"
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                        />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="submit" className="btn text-white text-center" style={{ backgroundColor: "#895cea" }}>Create Community</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default CommunityCreateForm;