import { generateClient } from "aws-amplify/api";
import { getCurrentUser } from "aws-amplify/auth";
import { listUsers } from "../graphql/queries";


const client = generateClient();

export const fetchInAppUser = async() => {
    try{
      const user = await getCurrentUser();
      const data = await getAppUserByCognitoEmail(user.signInDetails.loginId);
      if(data){
          try{
          if(data.listUsers.items.length > 0){
            return(data.listUsers.items[0]);
          }}catch(err){ 
          
            return(false);
          }
      }
    } catch (err) {
      console.log(err);
    }
    }


const getAppUserByCognitoEmail = async (userEmail) => {
    console.log(userEmail,'  in getAppUserByCognitoEmail');
    const query = { filter: {email: {eq: userEmail}} };
    const response = await client.graphql({ query: listUsers, variables: query });
    return response.data;
    };
