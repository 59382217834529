import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { useNavigate } from 'react-router-dom';
import { downloadData } from 'aws-amplify/storage';

import { Classes, Section, SectionCard, Button, Text, H2 } from '@blueprintjs/core';


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const CarouselComponent = ({ title, deviceType, communities = [] }) => {

    const navigate = useNavigate();
    const [canvases, setCanvases] = useState({});

    useEffect(() => {
        const fetchCanvases = async () => {
            try {
                if (!communities.length) return;
                const canvasPromises = communities.map(async (community) => {
                    const canvasKey = community.canvas;
                    let canvasData = null;
                    try {
                        const downloadResult = await downloadData({
                            path: canvasKey,
                            // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
                        }).result;
                        canvasData = await downloadResult.body.text();
                        // Alternatively, you can use `downloadResult.body.blob()`
                        // or `downloadResult.body.json()` get read body in Blob or JSON format.
                        console.log('Succeed: ', canvasData);
                    } catch (error) {
                        console.log('Error : ', error);
                    }
                    return { communityId: community.id, canvasData };
                });

                const canvasDataArray = await Promise.all(canvasPromises);

                const canvasDataMap = canvasDataArray.reduce((acc, { communityId, canvasData }) => {
                    acc[communityId] = canvasData;
                    return acc;
                }, {});

                setCanvases(canvasDataMap);
            } catch (error) {
                console.error('Error fetching canvases:', error);
            }
        };

        fetchCanvases();
    }, [communities]);

    const drawCanvas = (canvasData) => {
        const canvas = document.createElement('canvas');
        canvas.width = 400;
        canvas.height = 400;
        const ctx = canvas.getContext('2d');
        if (canvasData.pixels.length === 0) {
            return canvas.toDataURL();
        } else {
            canvasData.pixels.forEach(({ x, y, color }) => {
                ctx.fillStyle = color;
                ctx.fillRect(x, y, 1, 1);
            });
            return canvas.toDataURL();
        }
    }


    return (
        <div className="carousel-container">
            <H2>{title}</H2>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={deviceType !== "mobile" ? true : false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                {communities.map((community, id) => {
                    const canvasData = canvases[community.id];
                    return (
                        <div key={id} className='border border-dark mr-3'>
                            {canvasData ? (
                                <img
                                    src={drawCanvas(JSON.parse(canvasData))}
                                    alt={`Canvas for ${community.name}`}
                                    onClick={() => navigate(`/community/${community.id}`, { state: { community, canvasData } })}
                                />
                            ) : (
                                <p>Loading canvas...</p>
                            )}
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default CarouselComponent;