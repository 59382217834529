/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      interests
      profilePicture
      communitiesOwned {
        nextToken
        __typename
      }
      communityMemberships {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      interests
      profilePicture
      communitiesOwned {
        nextToken
        __typename
      }
      communityMemberships {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      interests
      profilePicture
      communitiesOwned {
        nextToken
        __typename
      }
      communityMemberships {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCommunity = /* GraphQL */ `
  mutation CreateCommunity(
    $input: CreateCommunityInput!
    $condition: ModelCommunityConditionInput
  ) {
    createCommunity(input: $input, condition: $condition) {
      id
      name
      description
      type
      tags
      canvas
      members {
        nextToken
        __typename
      }
      basePixelsAllowed
      createdAt
      updatedAt
      userCommunitiesOwnedId
      owner
      __typename
    }
  }
`;
export const updateCommunity = /* GraphQL */ `
  mutation UpdateCommunity(
    $input: UpdateCommunityInput!
    $condition: ModelCommunityConditionInput
  ) {
    updateCommunity(input: $input, condition: $condition) {
      id
      name
      description
      type
      tags
      canvas
      members {
        nextToken
        __typename
      }
      basePixelsAllowed
      createdAt
      updatedAt
      userCommunitiesOwnedId
      owner
      __typename
    }
  }
`;
export const deleteCommunity = /* GraphQL */ `
  mutation DeleteCommunity(
    $input: DeleteCommunityInput!
    $condition: ModelCommunityConditionInput
  ) {
    deleteCommunity(input: $input, condition: $condition) {
      id
      name
      description
      type
      tags
      canvas
      members {
        nextToken
        __typename
      }
      basePixelsAllowed
      createdAt
      updatedAt
      userCommunitiesOwnedId
      owner
      __typename
    }
  }
`;
export const createCommunityMember = /* GraphQL */ `
  mutation CreateCommunityMember(
    $input: CreateCommunityMemberInput!
    $condition: ModelCommunityMemberConditionInput
  ) {
    createCommunityMember(input: $input, condition: $condition) {
      id
      user {
        id
        username
        email
        interests
        profilePicture
        createdAt
        updatedAt
        owner
        __typename
      }
      community {
        id
        name
        description
        type
        tags
        canvas
        basePixelsAllowed
        createdAt
        updatedAt
        userCommunitiesOwnedId
        owner
        __typename
      }
      role
      pixelsToday
      createdAt
      updatedAt
      userCommunityMembershipsId
      communityMembersId
      owner
      __typename
    }
  }
`;
export const updateCommunityMember = /* GraphQL */ `
  mutation UpdateCommunityMember(
    $input: UpdateCommunityMemberInput!
    $condition: ModelCommunityMemberConditionInput
  ) {
    updateCommunityMember(input: $input, condition: $condition) {
      id
      user {
        id
        username
        email
        interests
        profilePicture
        createdAt
        updatedAt
        owner
        __typename
      }
      community {
        id
        name
        description
        type
        tags
        canvas
        basePixelsAllowed
        createdAt
        updatedAt
        userCommunitiesOwnedId
        owner
        __typename
      }
      role
      pixelsToday
      createdAt
      updatedAt
      userCommunityMembershipsId
      communityMembersId
      owner
      __typename
    }
  }
`;
export const deleteCommunityMember = /* GraphQL */ `
  mutation DeleteCommunityMember(
    $input: DeleteCommunityMemberInput!
    $condition: ModelCommunityMemberConditionInput
  ) {
    deleteCommunityMember(input: $input, condition: $condition) {
      id
      user {
        id
        username
        email
        interests
        profilePicture
        createdAt
        updatedAt
        owner
        __typename
      }
      community {
        id
        name
        description
        type
        tags
        canvas
        basePixelsAllowed
        createdAt
        updatedAt
        userCommunitiesOwnedId
        owner
        __typename
      }
      role
      pixelsToday
      createdAt
      updatedAt
      userCommunityMembershipsId
      communityMembersId
      owner
      __typename
    }
  }
`;
export const updatePixels = /* GraphQL */ `
  mutation UpdatePixels(
    $canvasKey: String!
    $x: Int!
    $y: Int!
    $color: String!
  ) {
    updatePixels(canvasKey: $canvasKey, x: $x, y: $y, color: $color)
  }
`;
