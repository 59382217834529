import React, { createContext, useState } from 'react';

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [inAppUser, setInAppUser] = useState(null);
    const [community, setCommunity] = useState(null);

    return (
        <GlobalStateContext.Provider value={{ inAppUser, setInAppUser, community, setCommunity }}>
            {children}
        </GlobalStateContext.Provider>
    );
};