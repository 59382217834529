import React, { useEffect, useState, useContext } from 'react';
import Header from './Header';
import { generateClient, get } from 'aws-amplify/api';
import { getCommunity, listCommunityMembers, } from '../graphql/queries';
import { createCommunityMember, updatePixels, updateCommunityMember } from '../graphql/mutations';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { downloadData } from 'aws-amplify/storage';

import { fetchInAppUser } from '../funcs/fetchUser';
import { GlobalStateContext } from '../GlobalStateContext';

import { useParams, useLocation } from 'react-router-dom';

import { SketchPicker } from 'react-color';

const CommunityView = (props) => {

    const { id } = useParams();
    const location = useLocation();

    const [community, setCommunity] = useState(location.state.community || null);
    const [loading, setLoading] = useState(!community);
    const [userPixelsUsed, setUserPixelsUsed] = useState(localStorage.getItem('communityMember') && JSON.parse(localStorage.getItem('communityMember')).communityMembersId === location.state.community.id ? JSON.parse(localStorage.getItem('communityMember')).pixelsToday : 0);
    const [communityMember, setCommunityMember] = useState(localStorage.getItem('communityMember') && JSON.parse(localStorage.getItem('communityMember')).communityMembersId === location.state.community.id ? JSON.parse(localStorage.getItem('communityMember')) : 'waiting');
    const { inAppUser, setInAppUser } = useContext(GlobalStateContext || location.state.inAppUser);
    const [selectedColor, setSelectedColor] = useState('black');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [canvasData, setCanvasData] = useState({ pixels: [] });

    const client = generateClient({ authMode: 'userPool' });

    useEffect(() => {
        const initialize = async () => {
            if (!inAppUser) {
                const user = await fetchInAppUser();
                await setInAppUser(user);
            }

            const fetchCanvasData = async () => {
                let canvasData = null;
                try {
                    const canvasKey = community.canvas;
                    const downloadResult = await downloadData({
                        path: canvasKey,
                        options: {
                            cacheControl: 'no-cache',
                        }
                    }).result;
                    canvasData = await downloadResult.body.text();
                    setCanvasData(JSON.parse(canvasData));
                    console.log('Succeed: ', canvasData);
                } catch (error) {
                    console.log('Error : ', error);
                }
            };

            const getComUsers = async () => {
                const query = { filter: { communityMembersId: { eq: community.id }, userCommunityMembershipsId: { eq: inAppUser.id } } };
                try {
                    const response = await client.graphql({ query: listCommunityMembers, variables: query });
                    if (response.data.listCommunityMembers.items.length > 0) {
                        const member = response.data.listCommunityMembers.items[0];
                        setCommunityMember(member);
                        setUserPixelsUsed(member.pixelsToday);
                        localStorage.setItem('communityMember', JSON.stringify(member));
                    } else {
                        setCommunityMember(false);
                    }
                } catch (err) {
                    console.error('Error fetching community members:', err);
                }
            };

            if (community && inAppUser) {
                await fetchCanvasData();
                if (!localStorage.getItem('communityMember')) {
                    await getComUsers();
                }
            }
        };

        initialize();
    }, []);

    // useEffect(() => {
    //     const fetchCanvasData = async () => {
    //         let canvasData = null;
    //         try {
    //             const canvasKey = community.canvas;
    //             const downloadResult = await downloadData({
    //                 path: canvasKey,
    //                 options: {
    //                     cacheControl: 'no-cache',
    //                 }
    //             }
    //             ).result;
    //             canvasData = await downloadResult.body.text();
    //             setCanvasData(JSON.parse(canvasData));
    //             console.log('Succeed: ', canvasData);
    //         } catch (error) {
    //             console.log('Error : ', error);
    //         }
    //     }
    //     fetchCanvasData();
    //     setCommunityMember('waiting');
    //     console.log(`comm user: ${communityMember}`);
    // }, []);

    useEffect(() => {
        drawCanvas(canvasData);
    }, [canvasData]);

    const joinCommunity = async () => {
        if (!inAppUser) {
            try {
                await fetchInAppUser().then(user => {
                    setInAppUser(user);
                });
            } catch (err) {
                console.error('Error fetching user:', err);
            }
        }
        const communityMemberInput = {
            communityMembersId: community.id,
            userCommunityMembershipsId: inAppUser.id,
            role: 'MEMBER',
            pixelsToday: 0,
        };
        try {
            await client.graphql({ query: createCommunityMember, variables: { input: communityMemberInput } });
            setCommunityMember(communityMemberInput);
        } catch (err) {
            console.error('Error joining community:', err);
        }
    };

    // useEffect(() => {
    //     if (!GlobalStateContext.inAppUser && !location.state.inAppUser){
    //         fetchInAppUser().then(user => {
    //             setInAppUser(user);
    //         });
    //     }
    // },[])

    const handleColorChange = (color) => {
        setSelectedColor(color.hex)
    }

    const drawCanvas = (canvasDataSent) => {
        const canvas = document.getElementById('community-canvas');
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        console.log(community)
        canvas.width = 500;
        canvas.height = 500;
        console.log(canvasDataSent);
        if (canvasDataSent.pixels.length === 0) {
            console.log("hmmm no canvas here...");
            return;
        } else {
            ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before drawing
            canvasDataSent.pixels.forEach(pixel => {
                ctx.fillStyle = pixel.color;
                ctx.fillRect(pixel.x * 5, pixel.y * 5, 2, 2); // Assuming each pixel is 5x5
            });
        }
    };

    const handleCanvasClick = async (e) => {
        if (userPixelsUsed >= community.basePixelsAllowed) {
            toast.info("You've used all your pixels for the day!", {
                position: "top-center",
                autoClose: 3000,
            });
            return;
        }
        else if (!communityMember || communityMember === 'waiting') {
            toast.error("You must join the community to add pixels!", {
                position: "top-center",
                autoClose: 3000,
            });
            return;
        }

        const canvas = e.target;
        const rect = canvas.getBoundingClientRect();
        const x = Math.floor((e.clientX - rect.left) / canvas.width * 100);
        const y = Math.floor((e.clientY - rect.top) / canvas.height * 100);

        const pixelUpdateInput = {
            canvasKey: community.canvas,
            x,
            y,
            color: selectedColor,
            communityId: community.id,
        };

        try {
            await setUserPixelsUsed(userPixelsUsed + 1);
            if (localStorage.getItem('communityMember')) {
                localStorage.setItem('communityMember', JSON.stringify({ ...communityMember, pixelsToday: userPixelsUsed + 1 }));
            }
            // Update the local canvas state to reflect the change
            const updatedCanvas = canvasData;
            updatedCanvas.pixels.push({ color: selectedColor, x, y });
            // setCommunity({ ...community, canvas: JSON.stringify(updatedCanvas) });
            drawCanvas(updatedCanvas);
            await client.graphql({ query: updatePixels, variables: pixelUpdateInput, authMode: 'userPool' });
            await client.graphql({ query: updateCommunityMember, variables: { input: { id: communityMember.id, pixelsToday: userPixelsUsed + 1 } }, authMode: 'userPool' })
        } catch (err) {
            console.error('Error updating pixel:', err);
        }
    };

    // useEffect(() => {

    //     const getComUsers = async () => {
    //         const query = { filter: { communityMembersId: { eq: community.id }, userCommunityMembershipsId: { eq: inAppUser.id } } };
    //         try {
    //             const response = await client.graphql({ query: listCommunityMembers, variables: query });
    //             if (response.data.listCommunityMembers.items.length > 0) {
    //                 setCommunityMember(response.data.listCommunityMembers.items[0]);
    //                 setUserPixelsUsed(response.data.listCommunityMembers.items[0].pixelsToday);

    //             }
    //             else {
    //                 setCommunityMember(false);
    //             }
    //         } catch (err) {
    //             console.error('Error fetching community members:', err);
    //         }
    //     }




    //     if (community && inAppUser) {
    //         getComUsers();
    //     }


    // }, [])//[communityMember ? communityMember : null || 'waiting']);

    return (
        <>
            <Header />
            <div className="editor-container d-flex">
                <div className="sidebar bg-light p-3">
                    <h3>Tools</h3>

                    {communityMember === 'waiting' ? (
                        <button onClick={joinCommunity} className="btn btn-primary mt-3">Join Community</button>
                    ) : !communityMember ? (<button onClick={joinCommunity} className="btn btn-primary mt-3">Join Community</button>
                    ) : (
                        <div className="mt-3">
                            <button
                                onClick={() => setShowColorPicker(!showColorPicker)}
                                className="btn btn-secondary mb-3"
                            >
                                Pick Color
                            </button>
                            {showColorPicker && (
                                <SketchPicker
                                    color={selectedColor}
                                    onChangeComplete={handleColorChange}
                                />
                            )}
                            <p>Pixels Used: {userPixelsUsed}/{community.basePixelsAllowed}</p>
                        </div>
                    )}
                </div>
                <div className="main-content flex-grow-1 p-3">
                    <ToastContainer />
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            <h2>{community.name}</h2>
                            <p>{community.description}</p>
                            <div className="canvas-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <canvas
                                    id="community-canvas"
                                    width="500"
                                    height="500"
                                    style={{ border: '1px solid #000' }}
                                    onClick={communityMember !== 'waiting' && communityMember.pixelsToday < community.basePixelsAllowed ? handleCanvasClick : null}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default CommunityView;